export default {
	elementary: {
		students: {
			programs: [
				{
					id: 1,
					number: "13850",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.students.program1.name.label",
						"app.for_grades.label":
							"app.elementary.students.program1.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.students.program1.gefen_program_no.label",
						"app.method.label": "app.elementary.students.program1.method.label",
						"app.number_of_mentoring_hours.label":
							"app.elementary.students.program1.mentoring_hours.label",
						"app.programs_goals.label":
							"app.elementary.students.program1.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.elementary.students.program1.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.students.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.students.program1.order_with_another_budget.label",
					},
				},
				{
					id: 2,
					number: "13363",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.students.program2.name.label",
						"app.for_grades.label":
							"app.elementary.students.program2.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.students.program2.gefen_program_no.label",
						"app.method.label": "app.elementary.students.program2.method.label",
						"app.number_of_mentoring_hours.label":
							"app.elementary.students.program2.mentoring_hours.label",
						"app.programs_goals.label":
							"app.elementary.students.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.elementary.students.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.students.program2.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.students.program2.order_with_another_budget.label",
					},
				},
				{
					id: 3,
					number: "15202",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.students.program3.name.label",
						"app.for_grades.label":
							"app.elementary.students.program3.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.students.program3.gefen_program_no.label",
						"app.method.label": "app.elementary.students.program3.method.label",
						"app.number_of_mentoring_hours.label":
							"app.elementary.students.program3.mentoring_hours.label",
						"app.programs_goals.label":
							"app.elementary.students.program3.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.elementary.students.program3.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.students.program2.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.students.program2.order_with_another_budget.label",
					},
				},
			],
		},
		teachers: {
			programs: [
				{
					id: 1,
					number: "17350",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.teachers.program1.name.label",
						"app.for_grades.label":
							"app.elementary.teachers.program1.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.teachers.program1.gefen_program_no.label",
						"app.method.label": "app.elementary.teachers.program1.method.label",
						"app.programs_goals.label":
							"app.elementary.teachers.program1.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.pricing.label",
						"app.program_description.label":
							"app.elementary.teachers.program1.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.teachers.program1.order_with_another_budget.label",
					},
				},
				{
					id: 2,
					number: "15202",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.teachers.program2.name.label",
						"app.for_grades.label":
							"app.elementary.teachers.program2.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.teachers.program2.gefen_program_no.label",
						"app.method.label": "app.elementary.teachers.program2.method.label",
						"app.programs_goals.label":
							"app.elementary.teachers.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.program2.pricing.label",
						"app.program_description.label":
							"app.elementary.teachers.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.teachers.program1.order_with_another_budget.label",
					},
				},
			],
		},
	},
	middle: {
		students: {
			programs: [
				{
					id: 1,
					number: "13850",
					basicInfo: {
						"app.program_name.label": "app.middle.students.program1.name.label",
						"app.for_grades.label": "app.middle.students.program1.grades.label",
						"app.gefen_program_no.label":
							"app.middle.students.program1.gefen_program_no.label",
						"app.method.label": "app.middle.students.program1.method.label",
						"app.number_of_mentoring_hours.label":
							"app.middle.students.program1.mentoring_hours.label",
						"app.programs_goals.label":
							"app.middle.students.program1.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.middle.students.program1.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.students.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.students.program1.order_with_another_budget.label",
					},
				},
				{
					id: 2,
					number: "13363",
					basicInfo: {
						"app.program_name.label": "app.middle.students.program2.name.label",
						"app.for_grades.label": "app.middle.students.program2.grades.label",
						"app.gefen_program_no.label":
							"app.middle.students.program2.gefen_program_no.label",
						"app.method.label": "app.middle.students.program2.method.label",
						"app.number_of_mentoring_hours.label":
							"app.middle.students.program2.mentoring_hours.label",
						"app.programs_goals.label":
							"app.middle.students.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.middle.students.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.students.program2.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.students.program2.order_with_another_budget.label",
					},
				},
				{
					id: 3,
					number: "19297",
					basicInfo: {
						"app.program_name.label": "app.middle.students.program3.name.label",
						"app.for_grades.label": "app.middle.students.program3.grades.label",
						"app.gefen_program_no.label":
							"app.middle.students.program3.gefen_program_no.label",
						"app.method.label": "app.middle.students.program3.method.label",
						"app.number_of_mentoring_hours.label":
							"app.middle.students.program3.mentoring_hours.label",
						"app.programs_goals.label":
							"app.middle.students.program3.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.middle.students.program3.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.students.program3.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.students.program3.order_with_another_budget.label",
					},
				},
				{
					id: 4,
					number: "15202",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.students.program3.name.label",
						"app.for_grades.label":
							"app.elementary.students.program3.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.students.program3.gefen_program_no.label",
						"app.method.label": "app.elementary.students.program3.method.label",
						"app.number_of_mentoring_hours.label":
							"app.elementary.students.program3.mentoring_hours.label",
						"app.programs_goals.label":
							"app.elementary.students.program3.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.elementary.students.program3.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.students.program2.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.students.program2.order_with_another_budget.label",
					},
				},
			],
		},
		teachers: {
			programs: [
				{
					id: 1,
					number: "17350",
					basicInfo: {
						"app.program_name.label": "app.middle.teachers.program1.name.label",
						"app.for_grades.label": "app.middle.teachers.program1.grades.label",
						"app.gefen_program_no.label":
							"app.middle.teachers.program1.gefen_program_no.label",
						"app.method.label": "app.middle.teachers.program1.method.label",
						"app.programs_goals.label":
							"app.middle.teachers.program1.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.pricing.label",
						"app.program_description.label":
							"app.middle.teachers.program1.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.teachers.program1.order_with_another_budget.label",
					},
				},
				{
					id: 2,
					number: "15238",
					basicInfo: {
						"app.program_name.label": "app.middle.teachers.program2.name.label",
						"app.for_grades.label": "app.middle.teachers.program2.grades.label",
						"app.gefen_program_no.label":
							"app.middle.teachers.program2.gefen_program_no.label",
						"app.method.label": "app.middle.teachers.program2.method.label",
						"app.programs_goals.label":
							"app.middle.teachers.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.pricing.label",
						"app.program_description.label":
							"app.middle.teachers.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.teachers.program1.order_with_another_budget.label",
					},
				},
				{
					id: 3,
					number: "19297",
					basicInfo: {
						"app.program_name.label": "app.middle.teachers.program3.name.label",
						"app.for_grades.label": "app.middle.teachers.program3.grades.label",
						"app.gefen_program_no.label":
							"app.middle.teachers.program3.gefen_program_no.label",
						"app.method.label": "app.middle.teachers.program3.method.label",
						"app.programs_goals.label":
							"app.middle.teachers.program3.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.pricing.label",
						"app.program_description.label":
							"app.middle.teachers.program3.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.teachers.program1.order_with_another_budget.label",
					},
				},
				{
					id: 4,
					number: "15202",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.teachers.program2.name.label",
						"app.for_grades.label":
							"app.elementary.teachers.program2.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.teachers.program2.gefen_program_no.label",
						"app.method.label": "app.elementary.teachers.program2.method.label",
						"app.programs_goals.label":
							"app.elementary.teachers.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.program2.pricing.label",
						"app.program_description.label":
							"app.elementary.teachers.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.teachers.program1.order_with_another_budget.label",
					},
				},
			],
		},
	},
	high: {
		students: {
			programs: [
				{
					id: 1,
					number: "13850",
					basicInfo: {
						"app.program_name.label": "app.middle.students.program1.name.label",
						"app.for_grades.label": "app.middle.students.program1.grades.label",
						"app.gefen_program_no.label":
							"app.middle.students.program1.gefen_program_no.label",
						"app.method.label": "app.middle.students.program1.method.label",
						"app.number_of_mentoring_hours.label":
							"app.middle.students.program1.mentoring_hours.label",
						"app.programs_goals.label":
							"app.middle.students.program1.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.middle.students.program1.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.students.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.students.program1.order_with_another_budget.label",
					},
				},
				{
					id: 2,
					number: "13363",
					basicInfo: {
						"app.program_name.label": "app.middle.students.program2.name.label",
						"app.for_grades.label": "app.middle.students.program2.grades.label",
						"app.gefen_program_no.label":
							"app.middle.students.program2.gefen_program_no.label",
						"app.method.label": "app.middle.students.program2.method.label",
						"app.number_of_mentoring_hours.label":
							"app.middle.students.program2.mentoring_hours.label",
						"app.programs_goals.label":
							"app.middle.students.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.middle.students.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.students.program2.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.students.program2.order_with_another_budget.label",
					},
				},
				{
					id: 3,
					number: "19297",
					basicInfo: {
						"app.program_name.label": "app.middle.students.program3.name.label",
						"app.for_grades.label": "app.middle.students.program3.grades.label",
						"app.gefen_program_no.label":
							"app.middle.students.program3.gefen_program_no.label",
						"app.method.label": "app.middle.students.program3.method.label",
						"app.number_of_mentoring_hours.label":
							"app.middle.students.program3.mentoring_hours.label",
						"app.programs_goals.label":
							"app.middle.students.program3.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.middle.students.program3.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.students.program3.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.students.program3.order_with_another_budget.label",
					},
				},
				{
					id: 4,
					number: "15202",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.students.program3.name.label",
						"app.for_grades.label":
							"app.elementary.students.program3.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.students.program3.gefen_program_no.label",
						"app.method.label": "app.elementary.students.program3.method.label",
						"app.number_of_mentoring_hours.label":
							"app.elementary.students.program3.mentoring_hours.label",
						"app.programs_goals.label":
							"app.elementary.students.program3.goals.label",
						"app.programs_pricing.label":
							"app.elementary.students.pricing.label",
						"app.program_description.label":
							"app.elementary.students.program3.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.students.program2.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.students.program2.order_with_another_budget.label",
					},
				},
			],
		},
		teachers: {
			programs: [
				{
					id: 1,
					number: "17350",
					basicInfo: {
						"app.program_name.label": "app.middle.teachers.program1.name.label",
						"app.for_grades.label": "app.middle.teachers.program1.grades.label",
						"app.gefen_program_no.label":
							"app.middle.teachers.program1.gefen_program_no.label",
						"app.method.label": "app.middle.teachers.program1.method.label",
						"app.programs_goals.label":
							"app.middle.teachers.program1.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.pricing.label",
						"app.program_description.label":
							"app.middle.teachers.program1.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.teachers.program1.order_with_another_budget.label",
					},
				},
				{
					id: 2,
					number: "15238",
					basicInfo: {
						"app.program_name.label": "app.middle.teachers.program2.name.label",
						"app.for_grades.label": "app.middle.teachers.program2.grades.label",
						"app.gefen_program_no.label":
							"app.middle.teachers.program2.gefen_program_no.label",
						"app.method.label": "app.middle.teachers.program2.method.label",
						"app.programs_goals.label":
							"app.middle.teachers.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.pricing.label",
						"app.program_description.label":
							"app.middle.teachers.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.teachers.program1.order_with_another_budget.label",
					},
				},
				{
					id: 3,
					number: "19297",
					basicInfo: {
						"app.program_name.label": "app.middle.teachers.program3.name.label",
						"app.for_grades.label": "app.middle.teachers.program3.grades.label",
						"app.gefen_program_no.label":
							"app.middle.teachers.program3.gefen_program_no.label",
						"app.method.label": "app.middle.teachers.program3.method.label",
						"app.programs_goals.label":
							"app.middle.teachers.program3.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.pricing.label",
						"app.program_description.label":
							"app.middle.teachers.program3.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.middle.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.middle.teachers.program1.order_with_another_budget.label",
					},
				},
				{
					id: 4,
					number: "15202",
					basicInfo: {
						"app.program_name.label":
							"app.elementary.teachers.program2.name.label",
						"app.for_grades.label":
							"app.elementary.teachers.program2.grades.label",
						"app.gefen_program_no.label":
							"app.elementary.teachers.program2.gefen_program_no.label",
						"app.method.label": "app.elementary.teachers.program2.method.label",
						"app.programs_goals.label":
							"app.elementary.teachers.program2.goals.label",
						"app.programs_pricing.label":
							"app.elementary.teachers.program2.pricing.label",
						"app.program_description.label":
							"app.elementary.teachers.program2.description.label",
					},
					expansionInfo: {
						"app.order_program_with_gefen_budget.label":
							"app.elementary.teachers.program1.order_with_gefen_budget.label",
						"app.order_program_with_another_budget.label":
							"app.elementary.teachers.program1.order_with_another_budget.label",
					},
				},
			],
		},
	},
};
