<template>
	<WebsitePrograms></WebsitePrograms>
</template>

<script>
import WebsitePrograms from "@/components/website/programs/Index.vue";

export default {
	name: "WebsiteProgramsPage",

	components: {
		WebsitePrograms,
	},
};
</script>

<style></style>
