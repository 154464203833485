<template>
	<v-card-text>
		<table
			class="subtitle-1"
			:style="{
				direction: ['he', 'ar'].includes(program_lang) ? 'rtl' : 'ltr',
			}"
		>
			<tr class="info white--text">
				<td colspan="2" align="center">
					<div class="text-h5 font-weight-bold">
						{{ program_locale[program_lang][program.number] || program.number }}
					</div>
				</td>
			</tr>
			<tr v-for="(value, key, index) in program.basicInfo" :key="'b' + index">
				<td class="font-weight-bold">
					{{ program_locale[program_lang][key] }}
				</td>
				<td v-html="program_locale[program_lang][value]"></td>
			</tr>
			<template v-if="program.expansionInfo">
				<tr
					v-for="(value, key, index) in program.expansionInfo"
					:key="'e' + index"
				>
					<td colspan="2" class="pa-0">
						<v-expansion-panels flat tile>
							<v-expansion-panel>
								<v-expansion-panel-header
									class="info--text subtitle-1 font-weight-bold"
									>{{
										program_locale[program_lang][key]
									}}</v-expansion-panel-header
								>
								<v-expansion-panel-content class="text-start">
									<span v-html="program_locale[program_lang][value]"></span>
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</td>
				</tr>
			</template>
		</table>
	</v-card-text>
</template>

<script>
import locale from "@/locales/programs.json";

export default {
	name: "WebsiteProgramDetail",

	props: {
		program: {
			required: true,
		},
		program_lang: {
			required: true,
		},
	},

	data() {
		return {
			program_locale: locale,
		};
	},
};
</script>

<style scoped>
table {
	border-collapse: collapse;
	width: 100%;
}
tr {
	width: 100%;
	color: black;
}
td {
	border: 1px solid;
	padding: 16px 24px;
	width: 50%;
}
td:nth-child(1) {
	width: 20%;
}
tr:nth-child(2n + 1) {
	background: lavender;
}
</style>
