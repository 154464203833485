<template>
	<div>
		<Layout
			v-model="selected_program"
			title="app.select_program_type"
			label="number"
			icon="clipboard_text"
			:items="listPrograms"
			:school_type="school_type"
			:program_designed_for="program_designed_for"
		></Layout>

		<template v-if="selected_program != null">
			<v-card-text class="mt-7" id="program-detail">
				<div class="text-h6 black--text font-weight-bold">
					{{ $t("app.select_program_language") }}
				</div>

				<v-radio-group v-model="program_lang" row>
					<v-radio
						v-for="item in $defines.LOCALES"
						:key="item.code"
						:label="item.name"
						:value="item.code"
						color="greenAccent2"
					></v-radio>
				</v-radio-group>
			</v-card-text>

			<!-- Program detail -->
			<ProgramDetail
				v-if="program"
				:program="program"
				:program_lang="program_lang"
			></ProgramDetail>
		</template>
	</div>
</template>

<script>
import ProgramDetail from "@/components/website/programs/Detail.vue";
import programsData from "./programs";
import Layout from "@/components/website/programs/Layout.vue";

export default {
	name: "WebsiteProgramsList",

	props: {
		school_type: {
			required: true,
		},
		program_designed_for: {
			required: true,
		},
	},

	components: {
		Layout,
		ProgramDetail,
	},

	data() {
		return {
			selected_program: null,
			program_lang: this.$route.query.lang ?? "en",
			show_subscription: false,
			default_program: {
				id: 0,
				number: "app.only_subscription_to_skillsx_platform",
				basicInfo: {
					"app.for_whom.label": "app.subscription.for_whom.ans.label",
					"app.type_of_subscription.label":
						"app.subscription.subscription_type.ans.label",
					"app.subscription_duration.label":
						"app.subscription.subscription_duration.ans.label",
					"app.subscription_pricing.label":
						"app.subscription.subscription_pricing.ans.label",
					"app.how_to_purchase_subscription.label":
						"app.subscription.how_to_purchase.ans.label",
				},
			},
		};
	},

	watch: {
		selected_program() {
			this.$nextTick(() => {
				document.getElementById("program-detail").scrollIntoView({
					behavior: "smooth",
				});
			});
		},

		school_type() {
			this.selected_program = null;
		},

		program_designed_for() {
			this.selected_program = null;
		},
	},

	computed: {
		schoolType() {
			return this.$defines.SCHOOL_TYPES[this.school_type + 1];
		},

		programDesignedFor() {
			return this.$defines.PROGRAMS_DESIGNED_FOR[this.program_designed_for + 1];
		},

		listPrograms() {
			let result =
				programsData[this.schoolType][this.programDesignedFor].programs;
			result.find((item) => item.id == 0)
				? ""
				: result.push(this.default_program);
			return result;
		},

		program() {
			// It means last program is being requested
			if (this.listPrograms.length - 1 == this.selected_program) {
				return this.default_program;
			}

			return programsData[this.schoolType][
				this.programDesignedFor
			].programs.find((item) => item.id == this.selected_program + 1);
		},
	},

	methods: {
		languageName(payload) {
			return this.$defines.LOCALES.find((item) => item.code == payload).name;
		},
	},
};
</script>
