<template>
	<div>
		<v-container>
			<v-alert
				type="info"
				align="center"
				class="title font-weight-bold sticky"
				border="top"
				>{{ $t("app.general_message_about_programs") }}</v-alert
			>

			<v-card flat>
				<!-- Choose school type -->
				<v-card-text>
					<Layout
						v-model="school_type"
						title="app.select_school_type"
						:items="schoolTypes"
						icon="school"
					></Layout>
					<!-- <SelectSchoolType v-model="school_type"></SelectSchoolType> -->
				</v-card-text>

				<!-- Choose program type -->
				<v-card-text v-if="school_type != null" id="program-type">
					<Layout
						v-model="program_designed_for"
						title="app.select_the_program"
						:items="programTypes"
						icon="user"
						:school_type="school_type"
					></Layout>
				</v-card-text>

				<!-- Programs list on behalf of chosen type -->
				<v-card-text
					v-if="school_type != null && program_designed_for != null"
					id="program-list"
				>
					<ProgramsList
						:school_type="school_type"
						:program_designed_for="program_designed_for"
					></ProgramsList>
				</v-card-text>
			</v-card>

			<div id="contact_us">
				<Title :text="$t('app.home.listening_you')"></Title>
				<ContactInfo></ContactInfo>
			</div>
		</v-container>
	</div>
</template>

<script>
import ProgramsList from "@/components/website/programs/List.vue";
import ContactInfo from "@/components/website/GetInTouch.vue";
import Title from "@/components/website/Title.vue";
import Layout from "@/components/website/programs/Layout.vue";

export default {
	name: "WebsitePrograms",

	data() {
		return {
			school_type: null,
			program_designed_for: null,
		};
	},

	components: {
		ProgramsList,
		ContactInfo,
		Title,
		Layout,
	},

	watch: {
		school_type() {
			this.program_designed_for = null;
			this.scrollTo("program-type");
		},

		program_designed_for() {
			this.scrollTo("program-list");
		},
	},

	computed: {
		schoolTypes() {
			return [
				{
					name: "app.elementary",
				},
				{
					name: "app.middle",
				},
				{
					name: "app.high",
				},
			];
		},

		programTypes() {
			return [
				{
					name: "app.for_students",
				},
				{
					name: "app.for_teachers",
				},
			];
		},
	},

	methods: {
		scrollTo(id) {
			this.$nextTick(() => {
				document.getElementById(id).scrollIntoView({
					behavior: "smooth",
				});
			});
		},
	},
};
</script>

<style scoped>
.sticky {
	position: sticky;
	top: 0;
	z-index: 100000;
	width: 100%;
}
</style>
