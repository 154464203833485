var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card-text',[_c('v-card-text',{staticClass:"text-h5 font-weight-bold black--text",attrs:{"align":"center"}},[_vm._v(_vm._s(_vm.$t(_vm.title)))]),_c('v-item-group',{on:{"change":function($event){return _vm.$emit('input', _vm.selected)}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('v-container',[_c('v-row',_vm._l((_vm.items),function(item,index){return _c('v-col',{key:index,attrs:{"md":item.id == 0 ? '12' : ''}},[_c('v-item',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-card',{class:[
								'd-flex align-center justify-center elevation-10 home-features-card-wr',
								{ 'border-card-wr': active } ],on:{"click":toggle}},[_c('v-scroll-y-transition',[_c('v-card-text',{class:{ amberDarken1: active }},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-truncate",attrs:{"sm":"10"}},[_c('v-icon',{attrs:{"size":"30","color":"black"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values[_vm.icon]))]),_vm._v("   "),_c('span',{class:[
													'title black--text',
													{ amberDarken1: active } ]},[_vm._v(" "+_vm._s(_vm.$te(item[_vm.label]) ? _vm.$t(item[_vm.label]) : item[_vm.label])+" ")])],1),_c('v-col',{staticClass:"text-end"},[_c('v-icon',{attrs:{"size":"30","color":"black"}},[_vm._v(_vm._s(_vm.$vuetify.icons.values[ index == _vm.selected ? "check_circle" : "circle_outline" ]))])],1)],1)],1)],1)],1)]}}],null,true)})],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }