<template>
	<v-card-text>
		<v-card-text align="center" class="text-h5 font-weight-bold black--text">{{
			$t(title)
		}}</v-card-text>

		<v-item-group v-model="selected" @change="$emit('input', selected)">
			<v-container>
				<v-row>
					<v-col
						v-for="(item, index) in items"
						:key="index"
						:md="item.id == 0 ? '12' : ''"
					>
						<v-item v-slot="{ active, toggle }">
							<v-card
								:class="[
									'd-flex align-center justify-center elevation-10 home-features-card-wr',
									{ 'border-card-wr': active },
								]"
								@click="toggle"
							>
								<v-scroll-y-transition>
									<v-card-text :class="{ amberDarken1: active }">
										<v-row no-gutters>
											<v-col sm="10" class="text-truncate">
												<v-icon size="30" color="black">{{
													$vuetify.icons.values[icon]
												}}</v-icon>
												&ensp;
												<span
													:class="[
														'title black--text',
														{ amberDarken1: active },
													]"
												>
													{{ $te(item[label]) ? $t(item[label]) : item[label] }}
												</span>
											</v-col>
											<v-col class="text-end">
												<v-icon size="30" color="black">{{
													$vuetify.icons.values[
														index == selected
															? "check_circle"
															: "circle_outline"
													]
												}}</v-icon>
											</v-col>
										</v-row>
									</v-card-text>
								</v-scroll-y-transition>
							</v-card>
						</v-item>
					</v-col>
				</v-row>
			</v-container>
		</v-item-group>
	</v-card-text>
</template>

<script>
export default {
	name: "WebsiteProgramsLayout",

	props: {
		value: {
			required: true,
		},
		title: {
			required: true,
		},
		icon: {
			required: true,
		},
		items: {
			required: true,
		},
		label: {
			default: "name",
		},
		school_type: {},
		program_designed_for: {},
	},

	data() {
		return {
			selected: this.value,
		};
	},

	watch: {
		school_type() {
			this.selected = null;
		},

		program_designed_for() {
			this.selected = null;
		},
	},
};
</script>

<style>
.border-card-wr {
	border: 1px solid var(--v-amberDarken1-base) !important;
}
</style>
